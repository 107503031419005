// @flow 
import * as React from 'react';
import {Chip, SvgIcon} from "@mui/material";
import _ from "lodash";

type Props = {
  sentiment?: string | null,
  size?: 'small' | 'medium',
};
export const CallSentiment = ({sentiment, size}: Props) => (
  <Chip color={getColorBySentiment(sentiment)}
        icon={getIcon(sentiment)}
        size={size}
        sx={{borderRadius: '12px', px: size !== 'small' ? 2 : 0, py: size !== 'small' ? 1 : 0}}
        label={_.upperFirst(sentiment?.toLowerCase())}/>
)


const getIcon = (val?: string | null) => {
  if (val === 'NEGATIVE') {
    return <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
        <rect width="256" height="256" fill="none"/>
        <circle cx="92" cy="140" r="12" fill="currentColor"/>
        <circle cx="164" cy="140" r="12" fill="currentColor"/>
        <circle cx="128" cy="128" r="96" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="16"/>
        <polyline points="80 88 128 120 176 88" fill="none" stroke="currentColor" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="16"/>
        <path d="M104,184c7.6-5.05,14.19-8,24-8s16.4,2.95,24,8" fill="none" stroke="currentColor" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="16"/>
      </svg>
    </SvgIcon>
  }
  if (val === 'POSITIVE') {
    return <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
        <rect width="256" height="256" fill="none"/>
        <circle cx="128" cy="128" r="96" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="16"/>
        <circle cx="92" cy="108" r="12" fill="currentColor"/>
        <circle cx="164" cy="108" r="12" fill="currentColor"/>
        <path d="M168,152c-8.3,14.35-22.23,24-40,24s-31.7-9.65-40-24" fill="none" stroke="currentColor"
              stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/>
      </svg>
    </SvgIcon>
  }
  return <SvgIcon>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none"/>
      <circle cx="128" cy="128" r="96" opacity="0"/>
      <circle cx="128" cy="128" r="96" fill="none" stroke="currentColor" stroke-miterlimit="10"
              stroke-width="16"/>
      <line x1="88" y1="160" x2="168" y2="160" fill="none" stroke="currentColor" stroke-linecap="round"
            stroke-linejoin="round" stroke-width="16"/>
      <circle cx="92" cy="108" r="12" fill="currentColor"/>
      <circle cx="164" cy="108" r="12" fill="currentColor"/>
    </svg>
  </SvgIcon>
}

const getColorBySentiment = (val?: string | null) => {
  switch (val) {
    case 'NEGATIVE': return 'error';
    case 'POSITIVE': return 'success';
    default: return 'info';
  }
}