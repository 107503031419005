// @flow
import * as React from 'react';
import {useConfirm} from "../../../../../shared/ConfirmDialog/ConfirmDialogContext";
import {
  Box,
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Stack,
  Typography
} from "@mui/material";
import {
  CallHistoryCallType, CallHistoryInput, CallHistoryItemsQuery, CallHistoryResultInput, ClientViewOutput,
  useSaveCallHistoryMutation
} from "../../../../../enrollment-types";
import FormSelect from "../../../../../shared/FormSelect";
import AddIcon from "@mui/icons-material/Add";
import {stringifyCallResult, stringifyCallType} from "../../call-history-table/lib";
import {useEffect, useState} from "react";
import {getUserName} from "../../../../../shared/tools";
import {LoadingButton} from "@mui/lab";

type Props = {
  client?: ClientViewOutput;
  call?: CallHistoryItemsQuery['callHistoryItems']['data'][0];
  open: boolean;
  onClose: () => void;
};
export const CallFormModal = ({call, onClose, open, client}: Props) => {
  const [value, setValue] = useState<{callType?: CallHistoryCallType | null, result?: CallHistoryResultInput | null}>(
    {callType: call?.callType, result: call?.result as unknown as CallHistoryResultInput}
  )

  const close = () => {
    setValue({})
    onClose()
  }

  useEffect(() => {
    open && call && setValue({callType: call.callType, result: call.result as unknown as CallHistoryResultInput})
  }, [open, call])

  const [save, {loading}] = useSaveCallHistoryMutation({ onCompleted: close })

  if (!client) return null;

  const submit = () => {
    save({
      variables: {
        input: {
          ...value,
          phoneNumber: call?.phoneNumber || client.phoneNumber!,
          clientId: client.id,
          id: call?.id
        }
      }
    })
  }

  return (
    <Dialog
      sx={{'& .MuiPaper-root': {width: 500}}}
      open={open}
      onClose={close}
    >
      <DialogTitle component={Typography} variant={'h5'}>Meeting with {getUserName(client)}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} mt={1} >
          <FormSelect value={value.callType || ''} onChange={event => {
            setValue(prev => ({...prev, callType: event.target.value as CallHistoryCallType}))
          }} label={'Call Type'}>
            <MenuItem value={CallHistoryCallType.Inbound}>{stringifyCallType(CallHistoryCallType.Inbound)}</MenuItem>
            <MenuItem value={CallHistoryCallType.Outbound}>{stringifyCallType(CallHistoryCallType.Outbound)}</MenuItem>
          </FormSelect>

          <FormSelect value={value.result || ''} onChange={event => {
            setValue(prev => ({...prev, result: event.target.value as CallHistoryResultInput}))
          }} label={'Result'}>
            <MenuItem value={CallHistoryResultInput.SuccessfulSeeNotesForCallDetails}>{stringifyCallResult(CallHistoryResultInput.SuccessfulSeeNotesForCallDetails)}</MenuItem>
            <MenuItem value={CallHistoryResultInput.SuccessfulBookedCall}>{stringifyCallResult(CallHistoryResultInput.SuccessfulBookedCall)}</MenuItem>
            <MenuItem value={CallHistoryResultInput.SuccessfulWarmTransfer}>{stringifyCallResult(CallHistoryResultInput.SuccessfulWarmTransfer)}</MenuItem>
            <MenuItem value={CallHistoryResultInput.UnsuccessfulInvalidNumber}>{stringifyCallResult(CallHistoryResultInput.UnsuccessfulInvalidNumber)}</MenuItem>
            <MenuItem value={CallHistoryResultInput.UnsuccessfulLeftText}>{stringifyCallResult(CallHistoryResultInput.UnsuccessfulLeftText)}</MenuItem>
            <MenuItem value={CallHistoryResultInput.UnsuccessfulLeftVm}>{stringifyCallResult(CallHistoryResultInput.UnsuccessfulLeftVm)}</MenuItem>
            <MenuItem value={CallHistoryResultInput.UnsuccessfulLeftEmail}>{stringifyCallResult(CallHistoryResultInput.UnsuccessfulLeftEmail)}</MenuItem>
          </FormSelect>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary" variant={'outlined'}>
          Close
        </Button>
        <LoadingButton loading={loading}
                       onClick={submit}
                       disabled={!value.result || !value.callType}
                       color={'primary'}
                       variant={'contained'}>
          {call ? 'Save' : 'Create'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
