// @flow
import * as React from 'react';
import {Box, Chip, Divider, Stack, SvgIcon, Tab, Typography} from "@mui/material";
import {
  CallHistoryItemsQuery,
  ClientViewOutput, useCallHistoryItemsQuery
} from "../../../../enrollment-types";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import {useEffect, useState} from "react";
import {CallsList} from "./ui/calls-list";
import { TabPanel } from '@mui/lab';
import {CallSentiment} from "../call-sentiment";

type Props = {
  client?: ClientViewOutput;
};

export const CallHistory = ({client}: Props) => {
  const [tab, setTab] = useState('key_topics');
  const [call, setCall] = useState<CallHistoryItemsQuery['callHistoryItems']['data'][0] | undefined>()

  const {data, refetch, loading } = useCallHistoryItemsQuery({
    variables: {clientId: client?.id, page: {page: 0, size: 1000}},
    skip: !client?.id,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  })

  const calls = data?.callHistoryItems.data;

  useEffect(() => {
    calls?.length && setCall(prev => !prev ? calls[0] : prev);
  }, [calls]);

  return (
    <Stack direction={'row'} gap={3}>
      <CallsList client={client} onChange={refetch} calls={calls} loading={loading} active={call} onClick={setCall} />

      <Divider orientation={'vertical'} />

      <Stack gap={1} flex={1}>
        <TabContext value={tab}>
          <Stack direction={'row'} gap={3} alignItems={'center'} justifyContent={'space-between'}>
            <Box flex={1}>
              <TabList onChange={(e, val) => setTab(val)}>
                <Tab label={`Key topics`} value="key_topics"/>
                <Tab label={`Call summary`} value="sall_summary"/>
              </TabList>
              <Box sx={{borderBottom: 8, borderColor: 'divider', mt: -1}}/>
            </Box>
            <CallSentiment sentiment={call?.callHistoryAnalysis?.sentiment} />
          </Stack>

          <TabPanel value="key_topics">
            <Stack gap={2}>
              {call && !call.callHistoryAnalysis?.topics && <NoData/>}
              {call?.callHistoryAnalysis?.topics?.map((topic, i, array) => (
                <React.Fragment key={topic}>
                  <Typography>{topic}</Typography>
                  {i < array.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </Stack>
          </TabPanel>

          <TabPanel value="sall_summary">
            {call && !call.callHistoryAnalysis?.summary && <NoData />}
            <Typography sx={{whiteSpace: 'pre-line'}}>{call?.callHistoryAnalysis?.summary}</Typography>
          </TabPanel>

        </TabContext>
      </Stack>

    </Stack>
  );
};


const NoData = () => (
  <Box sx={{height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    <Typography><b>No data</b></Typography>
  </Box>
)

