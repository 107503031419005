import {createTheme} from '@mui/material';

interface Color {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export const colors: {
  text: {primary: string, secondary: string},
  primary: Color,
  secondary: Color,
  warning: Color,
  green: {variant1: string, variant2: string}
} = {
  text: {
    primary: '#242424',
    secondary: '#787885',
  },
  primary: {
    "50": "#EBF2FF",
    "100": "#D8E6FF",
    "200": "#C4DAFF",
    "300": "#9DC2FF",
    "400": "#76A9FF",
    "500": "#4F91FF",
    "600": "#2979FF",
    "700": "#2264D1",
    "800": "#1B4EA3",
    "900": "#133774",
  },
  secondary: {
    "50": "#EDF6EE",
    "100": "#DCEDDD",
    "200": "#CBE5CC",
    "300": "#A9D3AB",
    "400": "#87C289",
    "500": "#65B168",
    "600": "#07B422",
    "700": "#37833B",
    "800": "#2B662E",
    "900": "#1F4921",
  },
  warning: {
    "50": "#FFF8EA",
    "100": "#FFF1D6",
    "200": "#FFEAC1",
    "300": "#FFDC99",
    "400": "#FFCF70",
    "500": "#FFC147",
    "600": "#FFB41F",
    "700": "#D1941A",
    "800": "#A37314",
    "900": "#74520F",
  },
  green: {
    variant1: '#5ECB9E',
    variant2: '#029094'
  }
};


const Theme =  createTheme({
  palette: {
    primary: {
      light: colors.primary["400"],
      main: colors.primary["600"],
      dark: colors.primary["900"]
    },
    secondary: {
      light: colors.warning["200"],
      main: colors.warning["400"],
      dark: colors.warning["200"]
    },
    text: {
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    }
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            color: 'white',
            backgroundColor: '#1C434F',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: 'rgb(51, 121, 143)',
              boxShadow: 'none',
            },
            '&:active': {
              backgroundColor: '#1C434F',
              boxShadow: 'none',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            color: '#1C434F',
            borderColor: '#1C434F',
            '&:hover': {
              color: 'rgb(51, 121, 143)',
              borderColor: 'rgb(51, 121, 143)'
            },
            '&:active': {
              color: '#1C434F',
              borderColor: '#1C434F'
            },
          },
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            color: '#1C434F',
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: 5,
          fontWeight: 500,
          textTransform: 'none',
          fontSize: 16,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
      }
    },
    MuiDivider: {
      styleOverrides: {
        vertical: {
          height: 'auto',
          borderColor: 'rgba(0,0,0,0.12)',
        },
        root: {
          borderColor: 'rgba(0,0,0,0.26)',
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#1C434F',
        }
      }
    },
    MuiChip: {
     styleOverrides: {
        root: {
          borderRadius: 5,
          '& .MuiChip-labelSmall': {
            fontSize: 13
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '24px!important'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          '&.Mui-focused': {
            color: colors.green.variant2,
            background: 'white',
            paddingRight: 5
          }
        }
      }
    },

    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          '&.Mui-selected': {
            color: '#1C434F',
          },
          textTransform: 'none',
          fontSize: 16,
        },
      }
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#1C434F',
          height: 8,
        },
        scroller: {
          paddingBottom: 16,
        }
      }
    },

    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#1C434F !important',
          boxShadow: 'none',
        },
        root: {
          '& button': {
            color: 'white'
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '8px',
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: '48px',
          height: '48px',
          padding: '6px 26px 6px 16px',
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white!important',
          borderRadius: 4,
          border: '1px solid #CCD8DC'
        },
        /*inputMarginDense: {
          paddingBottom: '9px!important',
          paddingTop: '9px!important'
        }*/
      }
    }
  },
  typography: {
    fontWeightBold: 500,
    fontSize: 13,
    body1: {
      fontSize: 13,
    },
    fontFamily: [
      'Inter', 'Roboto', 'Helvetica Neue', 'sans-serif'
    ].join(','),
  },
});


Theme.typography.h1 = {
  fontSize: 48,
  lineHeight: '64px',
  fontWeight: 400,
  color: '#000',
  fontFamily: [
    'Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif'
  ].join(','),
  [Theme.breakpoints.up('sm')]: {
    fontSize: 56,
    lineHeight: '72px',
  },
}

Theme.typography.h2 = {
  fontSize: 40,
  lineHeight: '64px',
  fontWeight: 400,
  color: '#000',
  fontFamily: [
    'Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif'
  ].join(','),
  [Theme.breakpoints.up('sm')]: {
    fontSize: 48,
    lineHeight: '72px',
  },
}

Theme.typography.h3 = {
  fontSize: 24,
  lineHeight: '40px',
  fontWeight: 600,
  fontFamily: [
    'Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif'
  ].join(','),
  [Theme.breakpoints.up('sm')]: {
    fontSize: 32,
    lineHeight: '48px',
  },
}

Theme.typography.h4 = {
  fontSize: 18,
  lineHeight: '22px',
  fontWeight: 600,
  fontFamily: [
    'Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif'
  ].join(','),
}

Theme.typography.body1 = {
  fontSize: 13,
  lineHeight: 1.5,
  fontFamily: [
    'Inter', 'Roboto', 'Helvetica Neue', 'sans-serif'
  ].join(','),
}

export default Theme;

