import React, {Fragment} from "react";
import {ListSubheader, MenuItem} from "@mui/material";
import {AgentField, AgentOutput, AgentSortInput, AgentViewOutput} from "../enrollment-types";
import FormSelect from "../shared/FormSelect";
import useAvailableAgents from "./hooks/useAvailableAgents";
import useAgents from "./hooks/useAgents";
import {keycloak, KeycloakRoles} from "../shared/keycloak";
import {getUserName} from "../shared/tools";

interface AgentSelectorProps {
  onChangeAgent?: (agent?: AgentOutput | AgentViewOutput) => void;
  onChange: (id: string) => void;
  value?: string;
  showEmptyOption?: boolean;
  fullWidth?: boolean;
  className?: string;
  error?: string;
  disabled?: boolean;
}

export default function AgentSelector(props: AgentSelectorProps) {

  const [get, availableData] = useAvailableAgents();
  const [getAll, allData] = useAgents();
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    if (keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_AGENT_MANAGER)) {
      getAll({variables: {
          filter: {
            enabled: null,
          },
          page: {
            page: 0,
            size: 1000
          }
        }})
    } else {
      get()
    }
  }, []);

  React.useEffect(() => {
    setValue(props.value || '');
  }, [props.value])

  const loading = React.useMemo(() => {
    return allData.loading || availableData.loading;
  }, [allData, availableData])

  const agents: Array<AgentViewOutput | AgentOutput> = React.useMemo(() => {
    if (availableData.data?.availableAgents) {
      return availableData.data.availableAgents;
    }
    if (allData.data?.agents.data) {
      return allData.data?.agents.data
    }
    return []
  }, [allData, availableData])

  const rows = React.useMemo(() => {
    const result: any[] = [];

    if (props.showEmptyOption) {
      result.push({value: '', label: '-'});
    }

    const active = agents.filter((a) => {
      if ('enabled' in a && a.enabled === false) {
        return false;
      }
      return !('isCSR' in a && a.isCSR);
    });
    if (active.length) {
      result.push({value: 'Agents', label: 'Agents', type: 'header'});
      active.forEach(a => result.push({value: a.id, label: getAgentDisplayName(a)}));
    }
    const CSRs = agents.filter((a) => {
      if ('enabled' in a && a.enabled === false) {
        return false;
      }
      return 'isCSR' in a && a.isCSR;
    });
    if (CSRs.length) {
      result.push({value: 'CSRs', label: 'CSRs', type: 'header'});
      CSRs.forEach(a => result.push({value: a.id, label: getAgentDisplayName(a)}));
    }
    const disabled = agents.filter((a) => 'enabled' in a && a.enabled === false);
    if (disabled.length) {
      result.push({value: 'Disabled', label: 'Disabled', type: 'header'});
      disabled.forEach(a => result.push({value: a.id, disabled: true, label: getAgentDisplayName(a)}));
    }
    return result;
  }, [agents, props.showEmptyOption])

  return <FormSelect label={'Agent'}
                     fullWidth={props.fullWidth}
                     className={props.className}
                     value={value}
                     onChange={event => {
                       const value = event.target.value as string;
                       props.onChange(value)
                       if (props.onChangeAgent) {
                         props.onChangeAgent(value ? agents.find(a => a.id === value) : undefined);
                       }
                     }}
                     disabled={loading || props.disabled}
                     helperText={props.error ? props.error : undefined}
                     error={Boolean(props.error)}
  >
    {rows.map((row, index) => row.type === 'header' ? <ListSubheader key={index} className={'bold bg-white'}>{row.label}</ListSubheader> : <MenuItem disabled={row.disabled} key={index} value={row.value}>{row.label}</MenuItem>)}
  </FormSelect>
}

export function getAgentDisplayName(agent: AgentViewOutput | AgentOutput): string {
  let displayName = '';
  if (agent.firstName || agent.lastName) {
    displayName = getUserName(agent);
  } else if (agent.email) {
    displayName = agent.email;
  } else {
    displayName = 'Unknown agent'
  }
  return displayName;
}

