// @flow
import * as React from 'react';
import {
  CallHistoryItemsQuery,
  ClientViewOutput,
} from "../../../../../enrollment-types";
import {Box, Button, CircularProgress, IconButton, Skeleton, Stack, Typography} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import {useState} from "react";
import {CallFormModal} from "./call-form-modal";
import {CallCard} from "./call-card";

type Call = CallHistoryItemsQuery['callHistoryItems']['data'][0]

type Props = {
  client?: ClientViewOutput;
  calls?: Call[];
  loading: boolean;
  active?: Call;
  onChange: () => void;
  onClick: (call: Call) => void;
};

export const CallsList = ({client, calls, active, loading, onChange, onClick}: Props) => {
  const [open, setOpen] = useState(false)
  const [call, setCall] = useState<Call>()

  if (loading && !calls?.length) {
    return <Loading />
  }

  const onEditClick = (c: Call) => {
    setCall(c);
    setOpen(true)
  }

  const onEditModalClose = () => {
    setOpen(false);
    onChange();
  }

  return (
    <Stack gap={1}>
      <CallFormModal call={call} client={client} onClose={onEditModalClose} open={open} />
      <Stack direction={'row'} gap={2} justifyContent={'space-between'} alignItems={'center'} height={64}>
        <Button variant={'contained'}
                size={'small'}
                onClick={() => setOpen(true)}
                startIcon={<AddIcon />}
                color={'primary'}>ADD CALL</Button>
        {!loading && <IconButton
          size={'small'}
          onClick={() => onChange()}
          color={'primary'}>
          <RefreshIcon color={'action'}/>
        </IconButton>}
        {loading && <CircularProgress size={20} color={'secondary'} />}
      </Stack>
      {!calls?.length && <Typography align={'center'} lineHeight={10}>No data</Typography>}
      {calls?.map((c, index) => (
        <CallCard call={c} key={c.id}
                  sx={{bgcolor: c.id === active?.id ? '#f7fbff' : undefined}}
                  client={client}
                  onEditClick={() => onEditClick(c)}
                  onClick={() => onClick(c)} />
      ))}
    </Stack>
  );
};

const Loading = () => (
  <Stack gap={1}>
    <Box height={64} />
    {Array(4).fill(0).map((_, i) => (
      <Skeleton variant={'rounded'} height={81} width={446} />
    ))}
  </Stack>
)