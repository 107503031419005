import {CallHistoryCallType, CallHistoryResultInput, CallHistoryResultOutput} from "../../../../enrollment-types";

export const stringifyCallResult = (type?: CallHistoryResultOutput | CallHistoryResultInput | null) => {
  switch (type) {
    case CallHistoryResultOutput.SuccessfulSeeNotesForCallDetails: return 'Successful - See notes for call details';
    case CallHistoryResultOutput.UnsuccessfulInvalidNumber: return 'Unsuccessful - Invalid Number (Wrong/Disconnected)';
    case CallHistoryResultOutput.UnsuccessfulLeftText: return 'Unsuccessful - Left Text';
    case CallHistoryResultOutput.UnsuccessfulLeftVm: return 'Unsuccessful - Left VM';
    case CallHistoryResultOutput.SuccessfulBookedCall: return 'Successful - Booked Call';
    case CallHistoryResultOutput.UnsuccessfulLeftEmail: return 'Unsuccessful - Left Email';
    case CallHistoryResultOutput.SuccessfulWarmTransfer: return 'Successful - Warm Transfer';
    default: return '';
  }
}
export const getCallResultColor = (type?: CallHistoryResultOutput | CallHistoryResultInput | null) => {
  switch (type) {
    case CallHistoryResultOutput.SuccessfulSeeNotesForCallDetails: return 'success';
    case CallHistoryResultOutput.UnsuccessfulInvalidNumber: return 'error';
    case CallHistoryResultOutput.UnsuccessfulLeftText: return 'default';
    case CallHistoryResultOutput.UnsuccessfulLeftVm: return 'default';
    case CallHistoryResultOutput.SuccessfulBookedCall: return 'success';
    case CallHistoryResultOutput.UnsuccessfulLeftEmail: return 'default';
    case CallHistoryResultOutput.SuccessfulWarmTransfer: return 'success';
    default: return 'default';
  }
}

export const stringifyCallType = (type?: CallHistoryCallType | null) => {
  switch (type) {
    case CallHistoryCallType.Inbound: return 'Inbound call';
    case CallHistoryCallType.Outbound: return 'Outbound attempt';
    default: return '';
  }
}