// @flow
import * as React from 'react';
import {CallHistoryCallType, CallHistoryItemsQuery, ClientViewOutput} from "../../../../../enrollment-types";
import {Avatar, Box, CardActionArea, Chip, IconButton, Stack, SxProps, Typography} from "@mui/material";
import {getUserName} from "../../../../../shared/tools";
import {config} from "../../../../../config/config";
import moment from "moment";
import {getCallResultColor, stringifyCallResult, stringifyCallType} from "../../call-history-table/lib";
import {
  ArrowCircleDown,
  ArrowCircleUp,
} from "@mui/icons-material";
import {useState} from "react";
import Card from "../../../../../shared/Card";

type Props = {
  call: CallHistoryItemsQuery['callHistoryItems']['data'][0],
  client?: ClientViewOutput;
  onClick: () => void;
  onEditClick?: () => void;
  sx?: SxProps;
};
export const CallCard = ({call, client, onClick, onEditClick, sx = {}}: Props) => {
  const [disableRipple, setDisableRipple] = useState(false)
  const agentName = call.agent ? getUserName(call.agent) : ''
  const clientName = client ? getUserName(client) : ''
  return (
    <Card sx={{p: 0, ...sx}}>
      <CardActionArea sx={{p: 2, display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'space-between'}}
                      disableRipple={disableRipple}
                      onClick={onClick}>

          <Avatar alt={agentName} src={call.agent?.picUrl ? config.cdnUrl + call.agent.picUrl : undefined}/>

          <Box flex={1}>
            <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
              <Typography>
                <b>{agentName} with {clientName}</b>
              </Typography>
              <Typography color={'textSecondary'} variant={'body2'}>
                {moment(call.createdAt).format('L LT')}
              </Typography>

            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
              <Chip variant={'outlined'}
                    color={getCallResultColor(call.result)}
                    size={'small'}
                    label={stringifyCallResult(call.result)} />

              <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'flex-end'}>

                <Stack direction={'row'} spacing={.5} alignItems={'center'}>
                  {call.callType === CallHistoryCallType.Inbound && <ArrowCircleDown fontSize={'small'} color={'success'}/>}
                  {call.callType === CallHistoryCallType.Outbound && <ArrowCircleUp fontSize={'small'} color={'info'}/>}
                  <Typography  variant={'body2'} sx={{whiteSpace: 'nowrap'}}>
                    {stringifyCallType(call.callType)}
                  </Typography>
                </Stack>

                {onEditClick && <IconButton size={'small'} onClick={r => {
                  r.stopPropagation();
                  onEditClick()
                }}
                             onMouseEnter={() => setDisableRipple(true)}
                             onMouseLeave={() => setDisableRipple(false)}>
                  <img src={'/img/mode.svg'} width={20} height={20}/>
                </IconButton>}

              </Stack>
            </Stack>
          </Box>
      </CardActionArea>

    </Card>
  );
}