// @flow
import * as React from 'react';
import Card from "../../../../shared/Card";
import {Button, ButtonGroup, Menu, Stack, Typography} from "@mui/material";
import {QuoteYear} from "./ui/quote-year";
import {
  MedicareQuoteFilterExtraBenefit,
  PlanYear,
  useCreateMedicareQuoteForClientMutation,
  useMedicareQuoteQuery
} from "../../../../types";
import {client as apolloClient} from "../../../../Apollo/ApolloClient";
import {ClientViewOutput} from "../../../../enrollment-types";
import moment from "moment/moment";
import Param from "../../../../shared/Param";
import {config} from "../../../../config/config";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import {enqueueSnackbar} from "notistack";

type Props = {
  client?: ClientViewOutput,
};
export const Quoting = ({client}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [createQuote] = useCreateMedicareQuoteForClientMutation({client: apolloClient});

  const {data} = useMedicareQuoteQuery({
    client: apolloClient,
    skip: !client?.quoteId,
    variables: { id: client?.quoteId },
    fetchPolicy: 'cache-and-network'
  });
  const quote = data?.medicareQuote;

  const create = () => {
    if (client) {
      createQuote({
        variables: {
          clientId: client.id,
          data: {
            county: client.countyName,
            zip: client.zip,
            planYear: PlanYear.Year2025,
            filters: {
              extraBenefits: [MedicareQuoteFilterExtraBenefit.DrugCoverage],
            },
          }
        }
      })
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack component={Card} gap={2} flex={1}>
      <div className={'flex relative'}>
        <QuoteYear quote={quote}/>
        <Typography color={'textPrimary'} variant={'h4'}>Quoting</Typography>
      </div>

      {quote && <>
          <Param  label={'createdAt'} value={moment(quote?.createdAt).format('L H:mm:ss')}/>

          {(!!client && !client?.quoteId) && <Button onClick={create}
                                                   className={'mb-10'}
                                                   variant={'contained'}
                                                   color={'primary'}>Create quote</Button>}

          <ButtonGroup disabled={!client?.quoteId}>
              <Button href={`${config.quotingUrl}?clientId=${client?.id}`}
                      target={'_blank'}
                      className={'mb-10'}
                      variant={'contained'}
                      color={'primary'}>Open quote</Button>
              <Button
                  variant={'contained'}
                  size={'small'}
                  className={'mb-10'}
                  onClick={event => setAnchorEl(event.currentTarget)}
              >
                  <ArrowDropDownIcon />
              </Button>
          </ButtonGroup>
          <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
          >
              <MenuItem component={'a'} target={'_blank'}
                        href={`${config.resultsUrl}plans/${client?.zip}/${client?.countyName}?clientId=${client?.id}&resetFilters=true`}
                        onClick={handleClose} >Old version</MenuItem>
          </Menu>

        {client?.quoteId && <Typography onClick={() => {
          navigator.clipboard.writeText(`${config.resultsUrl}?quote=${client?.quoteId}`)
          enqueueSnackbar('Copied!', {variant: "info"});
        }} className={'pointer fs-12 mb-10 underline'} color={'textPrimary'}>Copy link</Typography>}
        {client?.agentMedicareQuote?.quoteId && <Typography onClick={() => {
          navigator.clipboard.writeText(`${config.resultsUrl}?adminQuote=${client?.agentMedicareQuote?.quoteId}`)
          enqueueSnackbar('Copied!', {variant: "info"});
        }} className={'pointer fs-12 mb-10 underline'} color={'textPrimary'}>Copy offer link</Typography>}
      </>}
    </Stack>
  );
};